<template>
  <div class="">
    <div class="">
      <div class="Tester">
        <b-container class="mt-5">
          <b-row>
            <b-col xl="12" class="card ms-auto me-auto">
              <b-card-header>
                <b-card-title>Utilities for testers</b-card-title>
              </b-card-header>

              <b-card-body>
                <b-alert
                  class="mt-5 w-md-50"
                  v-if="!envOverride"
                  :show="true"
                  variant="info"
                >
                  <p>
                    You can override the environment:
                    <b-select
                      @change="didChangeEnvironment"
                      v-model="selectedEnvironment"
                      :options="environments"
                    ></b-select>
                  </p>
                  <div v-if="selectedEnvironment !== initialEnvironment">
                    <p>
                      You have changed from
                      <b>{{ initialEnvironment }}</b> environment to
                      <b>{{ selectedEnvironment }}</b> and need to logout before
                      you can access the new environment.
                    </p>
                    <b-button @click="logout" variant="info" class="btn-sm"
                      >Logout</b-button
                    >
                  </div>
                </b-alert>

                <b-alert
                  class="mt-5 w-md-50"
                  v-if="envOverride"
                  :show="true"
                  variant="danger"
                >
                  <p>
                    Environment override active:
                    <b>{{ envOverride }}</b>
                  </p>
                  <b-button
                    @click="clearOverride"
                    variant="danger"
                    class="btn-sm"
                    >Clear</b-button
                  >
                </b-alert>
              </b-card-body>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import DevOps from '@puls-solutions/puls-devops-utils'
export default {
  name: 'Tester',
  components: {},
  data() {
    return {
      selectedEnvironment: null,
      initialEnvironment: null,
      environments: ['dev', 'staging', 'demo', 'www']
    }
  },
  mounted() {
    this.initialEnvironment = this.currentEnvironment
    this.selectedEnvironment = this.currentEnvironment
  },
  computed: {
    currentEnvironment() {
      return DevOps.WebApp.getEnvironment({})
    },
    envOverride() {
      return window.localStorage.envOverride
    }
  },
  methods: {
    clearOverride() {
      delete window.localStorage.envOverride
      this.logout()
    },
    logout() {
      delete localStorage.organizationName
      window.location.href = '/#/logout'
      setTimeout(() => {
        window.location.reload()
      }, 50)
      // window.location.href = '/#/logout'
    },
    didChangeEnvironment() {
      if (
        this.selectedEnvironment &&
        this.selectedEnvironment !== this.initialEnvironment
      ) {
        localStorage.setItem('envOverride', this.selectedEnvironment)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
